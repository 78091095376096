import React, { useState, useEffect } from 'react'
import { tr } from '../../global-pure'

export const BLOCKS_PER_HALVING = 210000
export const BLOCKS_PER_YEAR = BLOCKS_PER_HALVING / 4
export const BLOCKS_PER_MONTH = BLOCKS_PER_YEAR / 12
export const BLOCKS_PER_DAY = 144
export const BLOCKS_PER_HOUR = 6

const ords1to20 = [
  'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth',
  'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth', 'twentieth',
]
const ords20to100by10 = [
  'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety', 'hundred'
]
const ords20to100by10th = [
  'twentieth', 'thirtieth', 'fortieth', 'fiftieth', 'sixtieth', 'seventieth', 'eightieth', 'ninetieth', 'hundredth'
]

export function th(ord) {
  if (ord >= 1 && ord <= 20) return tr(ords1to20[ord-1])
  if (ord >= 20 && ord <= 100) {
    const tens = Math.floor((ord+1) / 10)
    const ones = ord % 10
    if (ones == 0) return tr(ords20to100by10th[tens-2])
    return `${tr('ord-'+ords20to100by10[tens-2])}${tr('ordinal-and')}${tr(ords1to20[ones-1])}`
  }
}

const BTCDate = () => {
  const [blockCount, setBlockCount] = useState(0)

  useEffect(() => {
    const refresh = () => {
      fetch('/blockcount').then(res => res.text().then(text => {
        setBlockCount(+text)
        setInterval(refresh, 10 * 60 * 1000)
      }))
    }
    refresh()
  })

  if (!blockCount || isNaN(blockCount)) {
    return (<></>)
  }

  const blockYear = Math.floor(blockCount / BLOCKS_PER_YEAR)
  const blockMonth = Math.floor((blockCount - blockYear * BLOCKS_PER_YEAR) / BLOCKS_PER_MONTH)
  const blockDay = Math.floor((blockCount - blockYear * BLOCKS_PER_YEAR - blockMonth * BLOCKS_PER_MONTH) / BLOCKS_PER_DAY)
  const blockHour = Math.floor((blockCount % BLOCKS_PER_MONTH) / BLOCKS_PER_HOUR) % 24
  const blockMinute = (blockCount % BLOCKS_PER_HOUR) * (60 / BLOCKS_PER_HOUR)

  const datetime = tr('{M}/{D}/{Y} {hh}:{mm}')
    .replaceAll('{M}', `${blockMonth+1}`)
    .replaceAll('{D}', `${blockDay+1}`)
    .replaceAll('{Y}', `${blockYear+1}`)
    .replaceAll('{hh}', `${(blockHour>9?'':'0')+blockHour}`)
    .replaceAll('{mm}', `${(blockMinute>9?'':'0')+blockMinute}`)

  return (
    <>
    <div>
      <p style={{
        textAlign: 'center',
        marginTop: '1em',
        marginBottom: '0',
        fontFamily: 'Manrope, sans-serif',
        fontWeight: 800,
        fontSize: 'x-large'
      }}>
        {`${tr('Block Clock')}: ${datetime} BTC`}
      </p>
    </div>
    <div>
      <p style={{
        textAlign: 'center',
        margin: '0',
        fontFamily: 'Manrope, sans-serif',
        fontWeight: 800,
      }}>
        {`${tr('Today is the ')}${th(blockDay+1)}${tr(' day of the ')}${th(blockMonth+1)}${tr(' month of the ')}${th(blockYear+1)}${tr(' year of Bitcoin in block time')}`}
      </p>
    </div>
    </>
  )
}

export default BTCDate
